// // const FirebaseConfig = {
// //   // apiKey: 'AIzaSyAOWoRQGoegkmwnCca7B2GG5GUG_ZP1lcM',
// //   // authDomain: 'emilus.firebaseapp.com',
// //   // databaseURL: 'https://emilus.firebaseio.com',
// //   // projectId: 'emilus',
// //   // storageBucket: 'emilus.appspot.com',
// //   // messagingSenderId: '807555350717',
// //   // appId: '1:807555350717:web:145ba7c21af47203a2f7d4',
// //   // measurementId: 'G-8KE7HJB252'

// //   // apiKey: 'AIzaSyBUdVQKSYiMrWXKZLhpcR4pmtIUFa-40w0',
// //   // authDomain: 'zapkartnew2022-dc8a8.firebaseapp.com',
// //   // projectId: 'zapkartnew2022-dc8a8',
// //   // storageBucket: 'zapkartnew2022-dc8a8.appspot.com',
// //   // messagingSenderId: '812753080859',
// //   // appId: '1:812753080859:web:63d1a2c52ff2a2a5161ebe',

// //   apiKey: 'AIzaSyDE96sBpGy5JJKHNwTHP3OuUSChRCIgH_U',
// //   authDomain: 'ecommerce-74b71.firebaseapp.com',
// //   projectId: 'ecommerce-74b71',
// //   storageBucket: 'ecommerce-74b71.appspot.com',
// //   messagingSenderId: '1031062435017',
// //   appId: '1:1031062435017:web:d6b5ac03cd40d344480918',
// // }

// // export default FirebaseConfig

// let FirebaseConfig = {}

// // FirebaseConfig = {

// //   apiKey: 'AIzaSyBUdVQKSYiMrWXKZLhpcR4pmtIUFa-40w0',
// //   authDomain: 'zapkartnew2022-dc8a8.firebaseapp.com',
// //   projectId: 'zapkartnew2022-dc8a8',
// //   storageBucket: 'zapkartnew2022-dc8a8.appspot.com',
// //   messagingSenderId: '812753080859',
// //   appId: '1:812753080859:web:63d1a2c52ff2a2a5161ebe',

// //   // apiKey: 'AIzaSyDE96sBpGy5JJKHNwTHP3OuUSChRCIgH_U',
// //   // authDomain: 'ecommerce-74b71.firebaseapp.com',
// //   // projectId: 'ecommerce-74b71',
// //   // storageBucket: 'ecommerce-74b71.appspot.com',
// //   // messagingSenderId: '1031062435017',
// //   // appId: '1:1031062435017:web:d6b5ac03cd40d344480918',
// // }
// if (process.env.REACT_APP_SITE_NAME === 'athathy') {
//   FirebaseConfig = {
//     // apiKey: 'AIzaSyDIQXZ_KL265rV0hgSvYH1f5EQo010Lnjo',
//     // authDomain: 'athathy-13dd6.firebaseapp.com',
//     // projectId: 'athathy-13dd6',
//     // storageBucket: 'athathy-13dd6.appspot.com',
//     // messagingSenderId: '295619267638',
//     // appId: '1:295619267638:web:5bb8523b198a33959bdc97',
//     apiKey: 'AIzaSyBiN2BkR5RlgWKdaRa-psRzFCgPZ265zis',
//     authDomain: 'athathy-766b1.firebaseapp.com',
//     projectId: 'athathy-766b1',
//     storageBucket: 'athathy-766b1.appspot.com',
//     messagingSenderId: '685790667408',
//     appId: '1:685790667408:web:3b3cd5dd6b24815747ee15',
//   }
// } else if (process.env.REACT_APP_SITE_NAME === 'awen') {
//   FirebaseConfig = {
//     apiKey: 'AIzaSyBUdVQKSYiMrWXKZLhpcR4pmtIUFa-40w0',
//     authDomain: 'zapkartnew2022-dc8a8.firebaseapp.com',
//     projectId: 'zapkartnew2022-dc8a8',
//     storageBucket: 'zapkartnew2022-dc8a8.appspot.com',
//     messagingSenderId: '812753080859',
//     appId: '1:812753080859:web:63d1a2c52ff2a2a5161ebe',
//   }
// } else {
//   switch (process.env.NODE_ENV) {
//     case 'development':
//       FirebaseConfig = {
//         apiKey: 'AIzaSyBUdVQKSYiMrWXKZLhpcR4pmtIUFa-40w0',
//         authDomain: 'zapkartnew2022-dc8a8.firebaseapp.com',
//         projectId: 'zapkartnew2022-dc8a8',
//         storageBucket: 'zapkartnew2022-dc8a8.appspot.com',
//         messagingSenderId: '812753080859',
//         appId: '1:812753080859:web:63d1a2c52ff2a2a5161ebe',
//       }
//       break
//     case 'production':
//       FirebaseConfig = {
//         apiKey: 'AIzaSyDE96sBpGy5JJKHNwTHP3OuUSChRCIgH_U',
//         authDomain: 'ecommerce-74b71.firebaseapp.com',
//         projectId: 'ecommerce-74b71',
//         storageBucket: 'ecommerce-74b71.appspot.com',
//         messagingSenderId: '1031062435017',
//         appId: '1:1031062435017:web:d6b5ac03cd40d344480918',
//       }
//       break
//     case 'test':
//       FirebaseConfig = {
//         apiKey: 'AIzaSyBUdVQKSYiMrWXKZLhpcR4pmtIUFa-40w0',
//         authDomain: 'zapkartnew2022-dc8a8.firebaseapp.com',
//         projectId: 'zapkartnew2022-dc8a8',
//         storageBucket: 'zapkartnew2022-dc8a8.appspot.com',
//         messagingSenderId: '812753080859',
//         appId: '1:812753080859:web:63d1a2c52ff2a2a5161ebe',
//       }
//       break
//     default:
//       break
//   }
// }

// export default FirebaseConfig

let FirebaseConfig = {}

// FirebaseConfig = {

//   apiKey: 'AIzaSyBUdVQKSYiMrWXKZLhpcR4pmtIUFa-40w0',
//   authDomain: 'zapkartnew2022-dc8a8.firebaseapp.com',
//   projectId: 'zapkartnew2022-dc8a8',
//   storageBucket: 'zapkartnew2022-dc8a8.appspot.com',
//   messagingSenderId: '812753080859',
//   appId: '1:812753080859:web:63d1a2c52ff2a2a5161ebe',

//   // apiKey: 'AIzaSyDE96sBpGy5JJKHNwTHP3OuUSChRCIgH_U',
//   // authDomain: 'ecommerce-74b71.firebaseapp.com',
//   // projectId: 'ecommerce-74b71',
//   // storageBucket: 'ecommerce-74b71.appspot.com',
//   // messagingSenderId: '1031062435017',
//   // appId: '1:1031062435017:web:d6b5ac03cd40d344480918',
// }
if (process.env.REACT_APP_SITE_NAME === 'athathy') {
  FirebaseConfig = {
    // apiKey: 'AIzaSyDIQXZ_KL265rV0hgSvYH1f5EQo010Lnjo',
    // authDomain: 'athathy-13dd6.firebaseapp.com',
    // projectId: 'athathy-13dd6',
    // storageBucket: 'athathy-13dd6.appspot.com',
    // messagingSenderId: '295619267638',
    // appId: '1:295619267638:web:5bb8523b198a33959bdc97',
    apiKey: 'AIzaSyBiN2BkR5RlgWKdaRa-psRzFCgPZ265zis',
    authDomain: 'athathy-766b1.firebaseapp.com',
    projectId: 'athathy-766b1',
    storageBucket: 'athathy-766b1.appspot.com',
    messagingSenderId: '685790667408',
    appId: '1:685790667408:web:3b3cd5dd6b24815747ee15',
  }
} else if (process.env.REACT_APP_SITE_NAME === 'awen') {
  FirebaseConfig = {
    // apiKey: 'AIzaSyDIQXZ_KL265rV0hgSvYH1f5EQo010Lnjo',
    // authDomain: 'athathy-13dd6.firebaseapp.com',
    // projectId: 'athathy-13dd6',
    // storageBucket: 'athathy-13dd6.appspot.com',
    // messagingSenderId: '295619267638',
    // appId: '1:295619267638:web:5bb8523b198a33959bdc97',
    apiKey: 'AIzaSyDE96sBpGy5JJKHNwTHP3OuUSChRCIgH_U',
    authDomain: 'ecommerce-74b71.firebaseapp.com',
    projectId: 'ecommerce-74b71',
    storageBucket: 'ecommerce-74b71.appspot.com',
    messagingSenderId: '1031062435017',
    appId: '1:1031062435017:web:d6b5ac03cd40d344480918',
  }
} else {
  switch (process.env.NODE_ENV) {
    case 'development':
      // FirebaseConfig = {
      //   // apiKey: 'AIzaSyBUdVQKSYiMrWXKZLhpcR4pmtIUFa-40w0',
      //   // authDomain: 'zapkartnew2022-dc8a8.firebaseapp.com',
      //   // projectId: 'zapkartnew2022-dc8a8',
      //   // storageBucket: 'zapkartnew2022-dc8a8.appspot.com',
      //   // messagingSenderId: '812753080859',
      //   // appId: '1:812753080859:web:63d1a2c52ff2a2a5161ebe',

      //   apiKey: 'AIzaSyDE96sBpGy5JJKHNwTHP3OuUSChRCIgH_U',
      //   authDomain: 'ecommerce-74b71.firebaseapp.com',
      //   projectId: 'ecommerce-74b71',
      //   storageBucket: 'ecommerce-74b71.appspot.com',
      //   messagingSenderId: '1031062435017',
      //   appId: '1:1031062435017:web:d6b5ac03cd40d344480918',
      // }
      if (process.env.REACT_APP_API_STAGE === 'Dev') {
        // FirebaseConfig = {
        //   apiKey: 'AIzaSyDE96sBpGy5JJKHNwTHP3OuUSChRCIgH_U',
        //   authDomain: 'ecommerce-74b71.firebaseapp.com',
        //   projectId: 'ecommerce-74b71',
        //   storageBucket: 'ecommerce-74b71.appspot.com',
        //   messagingSenderId: '1031062435017',
        //   appId: '1:1031062435017:web:d6b5ac03cd40d344480918',
        // }
        FirebaseConfig = {
          apiKey: 'AIzaSyDmZpETpKWwUkYbDJdmq9XKmpK3iTcs6Dc',
          authDomain: 'ecommerce-test-e6c33.firebaseapp.com',
          projectId: 'ecommerce-test-e6c33',
          storageBucket: 'ecommerce-test-e6c33.appspot.com',
          messagingSenderId: '2805389390',
          appId: '1:2805389390:web:31c1bd337020b2368f0b1f'
        };
      } else {
        FirebaseConfig = {
          apiKey: 'AIzaSyDVRszGmdIg-Me-79_x9yuntOdt6Mz2LdM',
          authDomain: 'zapkartlive.firebaseapp.com',
          projectId: 'zapkartlive',
          storageBucket: 'zapkartlive.appspot.com',
          messagingSenderId: '1010821971171',
          appId: '1:1010821971171:web:dcd6a429a1f65cb3ead9d6',
        }
      }
      break
    case 'production':
      if (process.env.REACT_APP_API_STAGE === 'Dev') {
        FirebaseConfig = {
          apiKey: 'AIzaSyDmZpETpKWwUkYbDJdmq9XKmpK3iTcs6Dc',
          authDomain: 'ecommerce-test-e6c33.firebaseapp.com',
          projectId: 'ecommerce-test-e6c33',
          storageBucket: 'ecommerce-test-e6c33.appspot.com',
          messagingSenderId: '2805389390',
          appId: '1:2805389390:web:31c1bd337020b2368f0b1f'
        };
        // FirebaseConfig = {
        //   apiKey: 'AIzaSyDE96sBpGy5JJKHNwTHP3OuUSChRCIgH_U',
        //   authDomain: 'ecommerce-74b71.firebaseapp.com',
        //   projectId: 'ecommerce-74b71',
        //   storageBucket: 'ecommerce-74b71.appspot.com',
        //   messagingSenderId: '1031062435017',
        //   appId: '1:1031062435017:web:d6b5ac03cd40d344480918',
        // }
      } else {
        FirebaseConfig = {
          apiKey: 'AIzaSyDVRszGmdIg-Me-79_x9yuntOdt6Mz2LdM',
          authDomain: 'zapkartlive.firebaseapp.com',
          projectId: 'zapkartlive',
          storageBucket: 'zapkartlive.appspot.com',
          messagingSenderId: '1010821971171',
          appId: '1:1010821971171:web:dcd6a429a1f65cb3ead9d6',
        }
      }
      break
    case 'test':
      FirebaseConfig = {
        apiKey: 'AIzaSyDE96sBpGy5JJKHNwTHP3OuUSChRCIgH_U',
        authDomain: 'ecommerce-74b71.firebaseapp.com',
        projectId: 'ecommerce-74b71',
        storageBucket: 'ecommerce-74b71.appspot.com',
        messagingSenderId: '1031062435017',
        appId: '1:1031062435017:web:d6b5ac03cd40d344480918',
      }
      break
    default:
      break
  }
}

export default FirebaseConfig
