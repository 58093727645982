import React, { useEffect, useState } from "react";
import { Menu, Dropdown, Badge, Avatar, List, Button } from "antd";
import {
  MailOutlined,
  BellOutlined,
  WarningOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
// import notificationData from 'assets/data/notification.data.json'
import Flex from "components/shared-components/Flex";
import notificationService from "services/Notification";
import {Link} from 'react-router-dom';

const getIcon = (icon) => {
  switch (icon) {
    case "mail":
      return <MailOutlined />;
    case "alert":
      return <WarningOutlined />;
    case "check":
      return <CheckCircleOutlined />;
    default:
      return <MailOutlined />;
  }
};

const getNotificationBody = (list) => {
  return list.length > 0 ? (
    <List
      size="small"
      itemLayout="horizontal"
      dataSource={list}
      renderItem={(item) => (
        <List.Item className="list-clickable">
          <Flex alignItems="center">
            <div className="pr-3">
              {item.img ? (
                <Avatar src={`/img/avatars/${item.img}`} />
              ) : (
                <Avatar
                  className={`ant-avatar-${item.type}`}
                  icon={getIcon(item.icon)}
                />
              )}
            </div>
            <div className="mr-1">
              <span
                style={{
                  color: "rgb(114, 132, 154)",
                  fontWeight: 400,
                  width: "295px",
                  display: "block",
                }}
                className=""
              >
                {item.sms.content}{" "}
              </span>
              <span className="text-gray-light">{item.desc}</span>
            </div>
            <small className="ml-auto">{`${new Date(
              item.createdAt * 1000
            ).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })} ${new Date(item.createdAt * 1000).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}`}</small>
          </Flex>
        </List.Item>
      )}
    />
  ) : (
    <div className="empty-notification">
      <img
        src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
        alt="empty"
      />
      <p className="mt-3">You have viewed all notifications</p>
    </div>
  );
};

export const NavNotification = () => {
  const [visible, setVisible] = useState(false);
  // const [data, setData] = useState(notificationData)
  const [list, setList] = useState([]);
  const getNotifications = async() => {
    const res = await notificationService.getNotifications();
    const data = res.data.filter((cur) => cur.readFlag === false)
    if (res) {
      setList(data);
      console.log('notifications are', data)
    }
  };
  useEffect(() => {
    getNotifications();
  }, []);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notification</h4>
        <Button
        // className="text-primary"
        // type="text"
        onClick={() => setList([])}
        // size="small"
        >
          Clear{" "}
        </Button>
      </div>
      <div className="nav-notification-body">{getNotificationBody(list)}</div>
      {list.length > 0 ? (
         <Link to ="/app/dashboards/notifications/notification-history/notification-list">
        <div className="nav-notification-footer">
          <a className="d-block" href="#/">
            View all
          </a>
        </div>
        </Link>
      ) : null}
    </div>
  );

  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={["click"]}
    >
      <Menu mode="horizontal">
        <Menu.Item key="notification">
          <Badge count={list.length}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavNotification;
